import React, { useEffect, useRef, useState } from "react";
import { IoArrowBack, IoCloseOutline } from "react-icons/io5";
import {
  colors,
  GlobalButton,
  GlobalButtonPlain,
  Row,
  TextInputRawWrapper,
  TextInputWrapper,
  theme,
  transition,
} from "../../../theme";
import PlacesAutocompleteInput from "../PlacesAutocompleteInput.component";
import styled from "styled-components";
import countryCodes from "../../../utils/country_codes.json";
import { CiSquarePlus } from "react-icons/ci";
import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { generateGoogleMapsUrl } from "../../../services/map.service";
import {
  formatDeliveryRequestMessage,
  getSharableUrl,
} from "../../../services/data_handler.service";
import FormMessage from "../FormMessage.component";
import MapSelect from "../Maps/MapSelect.component";
import useExternalAPI from "../../../hooks/useExternalAPI";
import {
  Delivery,
  ILocation,
  ILocationAddress,
} from "../../../types/interfaces";
import MapPreview from "../Maps/MapPreview.component";
import Loader from "../Loader.component";
import OrderDetails from "../../Landing/OrderDetails.component";
import { formHandler } from "../../../services/form_handler.service";
import config from "../../../config";
import { FaWhatsapp } from "react-icons/fa6";
import {
  Close,
  Info,
  LinkWrapper,
  TopSection,
} from "../../../styles/modal.styles";
import { CgTrack } from "react-icons/cg";

export interface OrderRequestData {
  origin: Delivery;
  destination: (Delivery & { id: string })[];
}

const OrderRequest: React.FC<{
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setVisible }) => {
  const [deliveries, setDeliveries] = useState<OrderRequestData>({
    origin: {
      address: "",
      phone: "",
    },
    destination: [
      {
        id: "1",
        address: "",
        phone: "",
      },
    ],
  });
  const [formMessage, setFormMessage] = useState<{
    message: string | null;
    status: "success" | "info" | "error";
  }>({ message: null, status: "info" });
  const formRef = useRef<HTMLFormElement | null>(null);
  const [showMap, setShowMaps] = useState<{
    origin: boolean;
    destinations: { id: string; show: boolean }[];
  }>({
    origin: false,
    destinations: [
      {
        id: "1",
        show: false,
      },
    ],
  });
  const [stage, setStage] = useState<number>(1);
  const [whatsappURL, setWhatsappURL] = useState<string>("");
  const { fetchData } = useExternalAPI();
  const [completeDetails, setCompleteDetails] = useState<{
    start: ILocationAddress;
    waypoints: ILocationAddress[];
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [completedOrder, setCompletedOrder] = useState<{
    code: string;
    shared_url: string;
  }>({ code: "", shared_url: "" });

  useEffect(() => {
    console.log({ completedOrder });
  }, [completedOrder]);

  const request = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormMessage({ status: "info", message: null });

    try {
      const cleaned_data = {
        origin: {
          address: deliveries.origin.address,
          phone: deliveries.origin.phone.startsWith("0")
            ? deliveries.origin.phone.replace(/^0+/, "")
            : deliveries.origin.phone,
          location: deliveries.origin.location,
        },
        destination: deliveries.destination.map((delivery) => ({
          id: delivery.id,
          address: delivery.address,
          phone: delivery.phone.startsWith("0")
            ? delivery.phone.replace(/^0+/, "")
            : delivery.phone,
          location: delivery.location,
        })),
      };
      const optimize = (
        e.currentTarget.elements.namedItem("optimize") as HTMLInputElement
      ).checked;

      if (stage === 1) {
        const details = await getOrderDetails(deliveries, optimize);
        if (!details) {
          return;
        }
        // console.log({ cleaned_data });
        const complete: any = {
          start: {
            address: cleaned_data.origin.address,
            lat: cleaned_data?.origin?.location?.lat || 0,
            lng: cleaned_data?.origin?.location?.lng || 0,
            phone: cleaned_data.origin.phone,
          },
          waypoints: [],
        };
        details?.drop_offs?.map((data: any, index: number) => {
          // console.log({ distanceData: data });
          const found = cleaned_data.destination.find(
            (e) =>
              e?.location?.lat === data.waypoint.lat &&
              e?.location?.lng === data.waypoint.lng
          );
          // console.log({ found });
          if (found)
            complete.waypoints.push({
              address: found.address,
              lat: found?.location?.lat || 0,
              lng: found?.location?.lng || 0,
              price: data?.price,
              phone: found?.phone,
            });
        });
        // console.log({ complete });
        setOrderDetails(details);
        setCompleteDetails(complete);
        setStage(2);
        return;
      }
      if (stage === 3) {
        window.open(whatsappURL, "_blank");
        setFormMessage({
          status: "success",
          message: `Request sent via WhatsApp. If done ✅, close this form.\n An assistant or courier will contact you soon. Thanks for your order! 📦`,
        });
        return;
      }

      const origin = completeDetails?.start?.address || "";
      const waypoints =
        completeDetails?.waypoints?.map((delivery) => delivery.address || "") ||
        [];
      const url = generateGoogleMapsUrl({ waypoints: [origin, ...waypoints] });
      // console.log(url);

      const formatted = formatDeliveryRequestMessage(completeDetails, url);

      const hasEmptyDeliveryKeys = Object.values(deliveries.origin).some(
        (value) => value === ""
      );
      const hasEmptyDestinationKeys = deliveries.destination.some((delivery) =>
        Object.values(delivery).some((value) => value === "")
      );

      if (hasEmptyDeliveryKeys || hasEmptyDestinationKeys) {
        setFormMessage({
          status: "error",
          message:
            "Please fill in all delivery details before sending the request.",
        });
        return;
      }

      // console.log({ completeDetails, url });

      const encodedMessage = encodeURIComponent(formatted);
      setWhatsappURL(
        `https://api.whatsapp.com/send?phone=${"+233534529541"}&text=${encodedMessage}`
      );

      // window.open(whatsappURL, "_blank");
      //  console.log({ formatted });

      setLoading(true);
      const response = await fetchData({
        url: `delivery/create`,
        method: "POST",
        body: {
          pickup: completeDetails?.start,
          drop_offs: completeDetails?.waypoints,
          optimize,
        },
      });
      if (response["status"]) {
        setFormMessage({
          status: "success",
          message: response["message"] || "Successful",
        });
        setCompletedOrder({
          code: response["data"]["code"] || "",
          shared_url: response["data"]["shared_url"] || "",
        });
        setStage(3);
      } else setFormMessage({ status: "error", message: response["message"] });
    } catch (e: any) {
      console.error({ e });
      setFormMessage({
        status: "error",
        message: e?.message || "An error occured while creating order.",
      });
    } finally {
      setLoading(false);
    }
  };

  const extractRoutes = (data: OrderRequestData): ILocation[] => {
    const { origin, destination } = data;

    // Ensure origin location is defined
    if (!origin.location) {
      throw new Error("Origin location is undefined");
    }

    // Filter out any destinations that do not have a location defined
    const validDestinations: ILocation[] = [];

    destination.map((data, index) => {
      if (data.location?.lat && data.location.lng)
        validDestinations.push({
          lat: data.location?.lat,
          lng: data.location?.lng,
        });
    });

    // Combine origin and valid destinations
    const routes: ILocation[] = [
      { lat: origin.location.lat, lng: origin.location.lng },
      ...validDestinations,
    ];

    return routes;
  };

  const getOrderDetails = async (data: OrderRequestData, optimize: boolean) => {
    try {
      setLoading(true);
      const routes = extractRoutes(data);
      const response = await fetchData({
        url: `delivery/summary`,
        method: "POST",
        body: { routes, optimize },
      });
      if (response["status"]) return response["data"];
      else {
        setFormMessage({ status: "error", message: response["message"] });
        return false;
      }
    } catch (e) {
      // console.error("An error has occurred while displaying map.", e);
      setFormMessage({
        status: "error",
        message: e instanceof Error ? e.message : "An error has occurred",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addDelivery = (
    deliveries: OrderRequestData,
    newDelivery: Delivery & { id: string },
    index: number,
    showMap: { id: string; show: boolean }[],
    newShowMap: { id: string; show: boolean }
  ) => {
    const updatedDestinations = [...deliveries.destination];
    updatedDestinations.splice(index, 0, newDelivery);

    const updatedShowMap = [...showMap];
    updatedShowMap.splice(index, 0, newShowMap);

    return {
      deliveries: { ...deliveries, destination: updatedDestinations },
      showMap: updatedShowMap,
    };
  };

  const removeDelivery = (
    deliveries: OrderRequestData,
    index: number,
    showMap: { id: string; show: boolean }[]
  ) => {
    const updatedDestinations = [...deliveries.destination];
    updatedDestinations.splice(index, 1);

    const updatedShowMap = [...showMap];
    updatedShowMap.splice(index, 1);

    return {
      deliveries: { ...deliveries, destination: updatedDestinations },
      showMap: updatedShowMap,
    };
  };

  return (
    <LoadScript
      googleMapsApiKey={
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY?.toString() || ""
      }
      libraries={["places"]}
    >
      <WidthController onSubmit={(e) => request(e)} ref={formRef}>
        <TopSection>
          <h4 style={{ margin: 0 }}>Oder Request Form</h4>
          <Close
            onClick={() => {
              if (formRef.current) {
                formRef.current.reset();
              }
              setStage(1);
              setFormMessage((e) => ({ ...e, message: null }));
              setDeliveries({
                origin: {
                  address: "",
                  phone: "",
                },
                destination: [
                  {
                    id: "1",
                    address: "",
                    phone: "",
                  },
                ],
              });
              setVisible(false);
            }}
            type="button"
          >
            <IoCloseOutline />
          </Close>
        </TopSection>
        <Info>
          {stage === 1 ? (
            <>
              We're currently only accepting deliveries whitin{" "}
              <strong style={{ color: theme.orange, fontStyle: "italic" }}>
                Accra, Ghana
              </strong>
              .
            </>
          ) : (
            <>
              Our optimized delivery routes ensure{" "}
              <strong style={{ color: theme.orange, fontStyle: "italic" }}>
                efficiency
              </strong>{" "}
              and{" "}
              <strong style={{ color: theme.orange, fontStyle: "italic" }}>
                speed
              </strong>
              , guaranteeing your clients receive their items on time without
              any{" "}
              <strong style={{ color: theme.orange, fontStyle: "italic" }}>
                hassle
              </strong>
              .
            </>
          )}
        </Info>
        <Row style={{ width: "100%", gap: 0 }}>
          <div
            style={{
              width: stage === 1 ? "100%" : 0,
              overflow: "hidden",
              height: stage === 1 ? "100%" : 0,
              transition: transition,
            }}
          >
            <LinkWrapper style={{ height: 40, overflow: "hidden" }}>
              <PlacesAutocompleteInput
                onLocationChange={(
                  e: google.maps.places.PlaceResult | null
                ) => {
                  // console.log({ "new location": e });
                  let location: any = null;
                  if (e?.geometry?.location) {
                    location = {
                      lat: e?.geometry?.location.lat(),
                      lng: e?.geometry?.location.lng(),
                    };
                  }

                  const address = e?.formatted_address;
                  address &&
                    setDeliveries({
                      ...deliveries,
                      origin: {
                        ...deliveries.origin,
                        address: address,
                        location,
                      },
                    });
                }}
                value={deliveries.origin.address}
                showMap={showMap.origin}
                toggleMapView={(e) =>
                  setShowMaps((shows) => ({ ...shows, origin: e }))
                }
                placeholder="Pickup location"
              />
            </LinkWrapper>
            {showMap.origin && (
              <div
                style={{
                  height: 400,
                  borderRadius: 10,
                  marginTop: 20,
                  overflow: "hidden",
                }}
              >
                <MapSelect
                  location={
                    deliveries.origin.location
                      ? {
                          address: deliveries.origin.address,
                          latitude: deliveries.origin.location.lat,
                          longitude: deliveries.origin.location.lng,
                        }
                      : undefined
                  }
                  submitLocation={(e) =>
                    setDeliveries({
                      ...deliveries,
                      origin: {
                        ...deliveries.origin,
                        address: e.address,
                        location: { lat: e.latitude, lng: e.longitude },
                      },
                    })
                  }
                />
              </div>
            )}
            <TextInputWrapper
              style={{
                padding: "10px 10px",
                border: "1px solid #e5e7eb",
                marginTop: 10,
              }}
            >
              <select
                style={{
                  fontSize: 18,
                  background: "transparent",
                  border: "none",
                  width: 100,
                  outline: "none",
                  color: theme.font,
                }}
                value={"233"}
              >
                {countryCodes.map((data, index) => (
                  <option
                    key={index}
                    value={data.code}
                  >{`${data.flag} +${data.code}`}</option>
                ))}
              </select>
              <TextInputRawWrapper
                placeholder="Enter sender's no."
                type="number"
                style={{ fontSize: 18 }}
                minLength={9}
                maxLength={9}
                onChange={(e) => {
                  setDeliveries({
                    ...deliveries,
                    origin: {
                      ...deliveries.origin,
                      phone: e.target.value,
                    },
                  });
                }}
                required={true}
                name="origin_contact"
              />
            </TextInputWrapper>
            {deliveries.destination.map((data, index) => (
              <div key={index + data.id}>
                <ToDivider>
                  <strong style={{ fontSize: 11, color: theme.orange }}>
                    Drop of {index + 1}
                  </strong>
                  {deliveries.destination.length > 1 && (
                    <AddMoreButton
                      onClick={() => {
                        const {
                          deliveries: updatedDeliveries,
                          showMap: updatedShowMap,
                        } = removeDelivery(
                          deliveries,
                          index,
                          showMap.destinations
                        );
                        setDeliveries(updatedDeliveries);
                        setShowMaps({
                          ...showMap,
                          destinations: updatedShowMap,
                        });
                      }}
                      type="button"
                    >
                      remove <LuMinus color={theme.orange} size={20} />
                    </AddMoreButton>
                  )}
                </ToDivider>
                <LinkWrapper style={{ height: 40, marginTop: 0 }}>
                  <PlacesAutocompleteInput
                    onLocationChange={(
                      e: google.maps.places.PlaceResult | null
                    ) => {
                      const address = e?.formatted_address;
                      let location: any = undefined;
                      if (
                        e?.geometry?.location?.lat &&
                        e?.geometry?.location?.lng
                      )
                        location = {
                          lat: e?.geometry?.location?.lat(),
                          lng: e?.geometry?.location?.lng(),
                        };
                      address &&
                        setDeliveries({
                          ...deliveries,
                          destination: deliveries.destination.map(
                            (item, idx) => {
                              if (idx === index) {
                                return {
                                  ...item,
                                  address,
                                  location,
                                };
                              }
                              return item;
                            }
                          ),
                        });
                    }}
                    value={deliveries.destination[index].address}
                    showMap={showMap.destinations[index].show}
                    toggleMapView={(e) =>
                      setShowMaps((shows) => ({
                        ...shows,
                        destinations: shows.destinations.map((item, idx) => {
                          if (index === idx)
                            return {
                              ...item,
                              show: e,
                            };
                          return item;
                        }),
                      }))
                    }
                    placeholder="Destination"
                  />
                </LinkWrapper>
                {showMap.destinations.find((e) => e.id === data.id)?.show && (
                  <div
                    style={{
                      height: 300,
                      borderRadius: 10,
                      marginTop: 20,
                      overflow: "hidden",
                    }}
                  >
                    <MapSelect
                      location={
                        data?.location
                          ? {
                              address: data?.address,
                              latitude: data?.location?.lat || 0,
                              longitude: data?.location?.lng || 0,
                            }
                          : undefined
                      }
                      submitLocation={(e) =>
                        setDeliveries({
                          ...deliveries,
                          destination: deliveries.destination.map(
                            (item, idx) => {
                              if (idx === index) {
                                return {
                                  ...item,
                                  address: e.address,
                                  location: {
                                    lat: e.latitude,
                                    lng: e.longitude,
                                  },
                                };
                              }
                              return item;
                            }
                          ),
                        })
                      }
                    />
                  </div>
                )}
                <TextInputWrapper
                  style={{
                    padding: "10px 10px",
                    border: "1px solid #e5e7eb",
                    marginTop: 10,
                  }}
                >
                  <select
                    style={{
                      fontSize: 18,
                      background: "transparent",
                      border: "none",
                      width: 100,
                      outline: "none",
                      color: theme.font,
                    }}
                    value={"233"}
                  >
                    {countryCodes.map((data, index) => (
                      <option key={index} value={data.code}>
                        {`${data.flag} +${data.code}`}
                      </option>
                    ))}
                  </select>
                  <TextInputRawWrapper
                    placeholder="Enter receiver's no."
                    type="number"
                    style={{ fontSize: 18 }}
                    minLength={9}
                    maxLength={9}
                    name="origin_contact"
                    required={true}
                    value={deliveries.destination[index].phone} // Add this line
                    onChange={(e) => {
                      setDeliveries({
                        ...deliveries,
                        destination: deliveries.destination.map((item, idx) => {
                          if (idx === index) {
                            return {
                              ...item,
                              phone: e.target.value,
                            };
                          }
                          return item;
                        }),
                      });
                    }}
                  />
                </TextInputWrapper>
                {deliveries.destination.length < config.MAX_DELIVERIES &&
                  deliveries.destination.length === index + 1 && (
                    <AddMoreButton
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        const id = `${Math.random()}`;
                        const newDestination = { id, phone: "", address: "" };
                        const {
                          deliveries: updatedDeliveries,
                          showMap: updatedShowMap,
                        } = addDelivery(
                          deliveries,
                          newDestination,
                          deliveries.destination.length,
                          showMap.destinations,
                          { id, show: false }
                        );
                        setDeliveries(updatedDeliveries);
                        setShowMaps({
                          ...showMap,
                          destinations: updatedShowMap,
                        });
                      }}
                      type="button"
                    >
                      <GoPlus color={theme.orange} size={20} /> Add
                    </AddMoreButton>
                  )}
              </div>
            ))}
            <Row style={{ gap: 5, marginTop: 10 }}>
              <input type="checkbox" id="optimize" name="optimize" />
              <label htmlFor="termsAndConditions" style={{ fontSize: 12 }}>
                check to optimize routes for shorter distance and cheaper price.{" "}
              </label>
            </Row>
          </div>
          <div
            style={{
              width: stage === 2 || stage === 3 ? "100%" : 0,
              overflow: "hidden",
              height: stage === 2 || stage === 3 ? "100%" : 0,
              transition: transition,
            }}
          >
            {completeDetails && (
              <div
                style={{
                  height: 200,
                  borderRadius: 10,
                  marginTop: 20,
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <MapPreview
                  start={completeDetails?.start}
                  waypoints={completeDetails?.waypoints}
                />
              </div>
            )}
            <OrderDetails
              code={completedOrder?.code}
              insights={orderDetails}
              orderRoutes={{
                pickup: completeDetails?.start,
                drop_offs: completeDetails?.waypoints,
              }}
            />
            <Row style={{ gap: 5, marginTop: 10 }}>
              <input
                type="checkbox"
                id="termsAndConditions"
                name="termsAndConditions"
                required={stage === 2}
              />
              <label htmlFor="termsAndConditions" style={{ fontSize: 12 }}>
                I agree to the{" "}
                <a
                  style={{ color: theme.orange }}
                  href="https://drive.google.com/file/d/1sjcusRyZt70S2CsUTH3PZChZXXWT0YWi/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </label>
            </Row>
          </div>
        </Row>

        <FormMessage
          message={formMessage.message}
          status={formMessage.status}
        />
        {loading ? (
          <Loader
            message=""
            color={theme.orange}
            style={{
              width: 300,
              marginTop: 10,
              justifyContent: "flex-start",
            }}
          />
        ) : (
          <Row>
            {stage === 2 && (
              <>
                <GlobalButton
                  style={{
                    borderRadius: 10,
                    width: "20%",
                    backgroundColor: theme.orange,
                    color: theme.background,
                    marginTop: 20,
                  }}
                  type="button"
                  onClick={() => setStage(1)}
                >
                  <IoArrowBack size={15} />
                </GlobalButton>
                <GlobalButton
                  style={{
                    borderRadius: 10,
                    width: "80%",
                    backgroundColor: theme.font,
                    color: theme.background,
                    marginTop: 20,
                  }}
                  type="submit"
                >
                  Complete Order
                </GlobalButton>
              </>
            )}
            {stage === 3 && (
              <>
                <GlobalButton
                  style={{
                    borderRadius: 10,
                    width: "20%",
                    backgroundColor: theme.orange,
                    color: theme.background,
                    marginTop: 20,
                  }}
                  type="button"
                  onClick={() => setStage(1)}
                >
                  <IoArrowBack size={15} />
                </GlobalButton>
                <GlobalButton
                  style={{
                    borderRadius: 10,
                    width: "65%",
                    backgroundColor: theme.font,
                    color: "white",
                    marginTop: 20,
                  }}
                  type="button"
                  onClick={() => {
                    const url = getSharableUrl(
                      completedOrder?.code,
                      completeDetails?.start?.phone || ""
                    );
                    window.open(url, "_blank");
                  }}
                >
                  <CgTrack />
                  Track Order
                </GlobalButton>
                <GlobalButton
                  style={{
                    borderRadius: 10,
                    width: "15%",
                    backgroundColor: theme.accent_gray,
                    color: "#023020",
                    marginTop: 20,
                  }}
                  type="submit"
                >
                  <FaWhatsapp size={20} />
                  {/* Share on Whatsapp */}
                </GlobalButton>
              </>
            )}
            {stage !== 2 && stage !== 3 && (
              <GlobalButton
                style={{
                  borderRadius: 10,
                  width: "100%",
                  backgroundColor: theme.font,
                  color: theme.background,
                  marginTop: 20,
                }}
                type="submit"
              >
                Confirm & Proceed
              </GlobalButton>
            )}
          </Row>
        )}
      </WidthController>
    </LoadScript>
  );
};

const WidthController = styled.form`
  width: 400px;
  transition: ${transition};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const WidthControll = styled.div`
  width: 400px;
  transition: ${transition};

  @media (max-width: 768px) {
    width: 400px;
  }
`;

const ToDivider = styled.div`
  border-left: 1.5px dotted ${theme.orange};
  height: 40px;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px;
`;

const AddMoreButton = styled(GlobalButtonPlain)`
  margin-left: 5px;
  display: flex;
  align-items: center;
  padding: 2px;
  transition: ${transition};
  border-radius: 5px;
  font-size: 12px;

  &:hover {
    color: ${theme.orange};
  }
`;

export default OrderRequest;
